.styles-module__r6gvQG__wrapper {
  justify-content: center;
  gap: 2px;
  min-height: 50px;
  padding: 0 10px 10px;
  display: flex;
}

.styles-module__r6gvQG__item {
  background-color: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
  width: 40px;
  height: 40px;
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.styles-module__r6gvQG__item > a {
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
}

.styles-module__r6gvQG__itemActive {
  background-color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
  color: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
}

.styles-module__r6gvQG__itemActive > a {
  color: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
}

.styles-module__r6gvQG__arrow span:before {
  font-size: 8px;
  display: block;
}

.styles-module__r6gvQG__itemInactive {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-variant-dark));
}

.styles-module__r6gvQG__leftIcon {
  display: block;
  transform: rotate(90deg);
}

.styles-module__r6gvQG__rightIcon {
  display: block;
  transform: rotate(270deg);
}

